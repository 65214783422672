svg.logo-municipalidad {
	width: 437px;
	height: 112px;
	fill: $color-logo-municipalidad;
	//filter: drop-shadow(0px 3px 3px rgba(0, 0, 0, 0.4));
}

svg.mun {
	width: 263px;
	height: 147px;
	cursor: pointer;
	.txt-cm, .txt-v, .txt-somos {
		fill: rgb(75, 75, 75);
		transition: fill .5s ease-in-out;
	}
	&:hover, &.active {
		//filter: drop-shadow(0px 3px 3px rgba(0, 0, 0, 0.4));
		.txt-cm {
			fill: $txt-cm-color;
		}
		.txt-v {
			fill: $txt-v-color;
		}
		.txt-somos {
			fill: $txt-somos-color;
		}
		&.no-shadow {
			filter: none;
		}
	}
}

svg.os {
	width: 321px;
	height: 103px;
	cursor: pointer;
	.txt-os, .txt-rg, .txt-m {
		fill: rgb(75, 75, 75);
		transition: fill .5s ease-in-out;
	}
	&:hover, &.active {
		filter: drop-shadow(0px 3px 3px rgba(0, 0, 0, 0.4));
		.txt-os {
			fill: $txt-os-color;
		}
		.txt-rg {
			fill: $txt-rg-color;
		}
		.txt-m {
			fill: $txt-m-color;
		}
		&.no-shadow {
			filter: none;
		}
	}
}