.cards-links {
	max-width: 960px;
	margin: 0 auto;
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	align-items: center;
	.card-link {
		overflow: hidden;
		position: relative;
		cursor: pointer;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		width: 250px;
		border: 1px solid #ccc;
		border-radius: .5em;
		padding: .5em 1em;
		margin: 2em;
		color: #000;
		.label-percent {
			position: absolute;
			display: block;
			right: 2px;
			top: 2px;
			width: 55%;
			height: 30%;
			background-color: $default-text-color;
			color: #fff;
			clip-path: polygon(100% 0, 0 0, 100% 100%);
			border-radius: 0 .5em 0 0;
			text-align: right;
			z-index: -1;
			.text {
				color: $secondary-institucional-color;
				font-size: 1.5rem;
				font-weight: 800;
				transform: rotate(45deg);
			}
		}
		.card-circle {
			width: 150px;
			height: 150px;
			border-radius: 50%;
			display: flex;
			justify-content: center;
			align-items: center;
			margin: 1em 0 2em 0;
			transition: background 2s ease-in-out;
			@include degradado-oficial();
			svg {
				width: 50px;
				fill: #fff;
			}
		}
		.title {
			font-size: 1.5rem;
			line-height: 1.8rem;
			text-transform: uppercase;
			font-weight: 800;
			text-align: center;
		}
		p {
			text-align: center;
			margin: 1em 0;
		}
		&:hover {
			.card-circle {
				@include degradado-oficial-invertido();
			}
		}
	}
}

@media (max-width: 600px) {
	.cards-links {
		.card-link {
			width: 100%;
			padding: .5em 1em;
			margin: 1em;
			display: grid;
			grid-template-columns: 80px 1fr;
			.label-percent {
				left: 2px;
				right: auto;
				bottom: 2px;
				top: auto;
				width: 100px;
				height: 100px;
				clip-path: polygon(0 0, 0% 100%, 100% 100%);
				border-radius: 0 0 0 .5em;
				text-align: left;
				display: flex;
				justify-content: flex-start;
				align-items: flex-end;
				.text {
					color: $secondary-institucional-color;
					font-size: 1.5rem;
					font-weight: 800;
					transform: rotate(0deg);
					padding: 6px 4px;
				}
			}
			.card-circle {
				width: 50px;
				height: 50px;
				margin: .5em 1em .5em 0;
				svg {
					width: 20px;
				}
			}
			.card-body {
				.title {
					font-size: 1rem;
					text-align: left;
				}
				p {
					text-align: left;
				}
			}
			.title {
				line-height: 1.2rem;
			}
		}
	}
}