nav {
	width: 100%;
	//height: 150px;
	padding-bottom: 1em;
	background-color: #333;
	color: #cdc4bb;
	background-image: url("./imgs/cross-stripes.png");
	background-repeat: repeat;
	.nav-wrapper {
		max-width: 960px;
		margin: 0 auto;
		padding: .5em 1em;
		width: 100%;
		display: flex;
		flex-direction: column;
		.contacto {
			display: flex;
			justify-content: flex-end;
			a.link-contacto {
				margin: 0 1em;
				display: flex;
				align-items: center;
				svg {
					width: 10px;
					fill: #cdc4bb;
					margin-right: .5em;
				}
				span {
					color: #cdc4bb;
					text-decoration: none;
					font-size: .8rem;
				}
				&:hover {
					svg {
						fill: $secondary-institucional-color;
					}
					span {
						color: $secondary-institucional-color;
					}
				}
			}
		}
		.nav-body {
			width: 100%;
			display: flex;
			justify-content: space-between;
			align-items: center;
			.logo {
				display: flex;
				align-items: center;
				svg {
					width: 100px;
				}
				.titulo {
					margin-left: 1em;
					width: 190px;
					font-weight: 800;
					font-size: 1.3rem;
					color: #cdc4bb;
				}
			}
			.nav-links {
				ul {
					list-style-type: none;
					display: flex;
					justify-content: flex-end;
					align-items: center;
					li a {
						font-weight: 600;
						padding: .5em 1em;
						color: $primary-institucional-color;
						transition: color .5s ease-out;
						text-decoration: none;
						&:last-child {
							padding-right: 0;
						}
						&:hover {
							color: $secondary-institucional-color;
						}
					}
				}
			}
		}
	}
}

@media (max-width: 700px) {
	nav {
		height: auto;
		.nav-wrapper {
			.contacto {
				a.link-contacto {
					margin-bottom: .8em;
				}
			}
			.nav-body {
				flex-direction: column;
				.nav-links {
					width: 100%;
					ul {
						width: 100%;
						margin-top: 1em;
						justify-content: space-around;
						flex-wrap: wrap;
						li a {
							&:first-child {
								padding-left: 0;
							}
						}
					}
				}
			}
		}
	}
}