.infos-links {
	max-width: 960px;
	margin: 0 auto;
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	align-items: center;
	.info-link {
		position: relative;
		overflow: hidden;
		cursor: pointer;
		display: flex;
		display: grid;
		grid-template-columns: 50px 250px;
		gap: 1em;
		border: 1px solid #ccc;
		border-radius: .5em;
		padding: .5em 1em;
		margin: 2em;
		color: #fff;
		.info-circle {
			z-index: 2;
			display: flex;
			justify-content: center;
			align-items: center;
			svg {
				width: 20px;
				fill: #fff;
			}
		}
		.info-body {
			z-index: 2;
			display: flex;
			justify-content: center;
			align-items: center;
			.title {
				font-size: 1rem;
				text-transform: uppercase;
				font-weight: 800;
				text-align: center;
			}
			p {
				text-align: center;
				margin: 1em 0;
			}
		}
		&::after {
			z-index: 0;
			position: absolute;
			content: "";
			width: 5px;
			height: 5px;
			border-radius: 50%;
			background-color: $secondary-institucional-color;
			display: block;
			transition: transform .5s ease-in-out;
		}
		&:hover {
			&::after {
				transform: scale(100);
			}
		}
	}
}

$info-color-titulo: #e89f00;
$info-link-color: #ee2737;

.info {
	max-width: 800px;
	margin: 0 auto;
	padding: .5em 1em;
	color: #161616;
	h1, h2, h3, h4, h5, h5 {
		color: $info-color-titulo;
		font-weight: 700;
	}
	p {
		//letter-spacing: 1px;
		//line-height: 1.5rem;
		font-size: 16px;
		  line-height: 24px;
		a {
			text-decoration: none;
			color: $info-link-color;
		}
	}
	ul, ol {
		list-style-type: disc;
		margin-bottom: 2em;
		li {
			margin-bottom: 1em;
			font-size: 1.1rem;
			a {
				text-decoration: none;
				color: $info-link-color;
			}
		}
	}
}

@media (max-width: 600px) {
	.infos-links {
		.info-link {
			width: 100%;
			padding: .5em 1em;
			margin: 1em;
			display: grid;
			grid-template-columns: 80px 1fr;
			.info-circle {
				width: 50px;
				height: 50px;
				margin: .5em 1em .5em 0;
				svg {
					width: 20px;
				}
			}
			.info-body {
				.title {
					font-size: 1rem;
					text-align: left;
				}
				p {
					text-align: left;
				}
			}
		}
	}
}