*, *::after, *::before {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	-webkit-font-smoothing: antialiased;
  	-moz-osx-font-smoothing: grayscale;
}

:root {
	--institucional1: #ee2737;
	--institucional2: #ffb81c;
}

html {
	scroll-behavior: smooth;
}

body {
	font-family: $primary-font;
	color: $default-text-color;
}

a {
	text-decoration: none;
	color: $primary-institucional-color;
	transition: colo .5s ease-out;
	&:hover {
		color: $secondary-institucional-color;
	}
}

section {
	padding: 2em 1em;
	h1 {
		position: relative;
		text-transform: uppercase;
		text-align: center;
		font-size: 3rem;
		//padding-bottom: 1em;
		color: $secondary-institucional-color;
		&::after {
			content: "";
			position: absolute;
			left: 50%;
			bottom: 0;
			height: 2px;
			width: 30%;
			display: block;
			background-color: #ccc;
			transform: translateX(-50%);
			border-radius: 1px;
		}
	}
	p {
		text-align: center;
	}
	p.leyenda {
		max-width: 750px;
		text-align: center;
		margin: 0 auto;
		font-size: 1.4rem;
		margin-top: 1em;
		font-style: italic;
		font-weight: 600;
	}
	&.bg-dark {
		background-color: #333;
		color: #fff;
	}
}

@media (max-width: 600px) {
	section {
		h1 {
			font-size: 2.5rem;
		}
		p {
			text-align: center;
		}
		p.leyenda {
			font-size: 1.1rem;
		}
	}
}
