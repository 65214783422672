.Contributor {
	padding: 3em 1em;
	background-color: #f6f6f6;
	.contributor-wrapper {
		max-width: 960px;
		margin: 0 auto;
		.body {
			display: grid;
			grid-template-areas: "title imagen" "leyenda imagen" "contribtn imagen";
			grid-template-columns: 2fr 1fr;
			.title {
				grid-area: title;
				h4 {
					font-size: 2.5rem;
					margin-block-start: 0;
					margin-block-end: 0;
				}
				h3 {
					font-size: 3rem;
					margin-block-start: 0;
					margin-block-end: 0;
					margin-bottom: 1em;
					color: #2185d0;
					text-shadow: 2px 2px 2px darken(#2185d0, 10%);
					//text-transform: uppercase;
					font-weight: 800;
				}
			}
			.leyenda {
				grid-area: leyenda;
				font-size: 1.1rem;
				font-style: italic;
    			font-weight: 600;
			}
			.imagen {
				grid-area: imagen;
				width: 100%;
			}
			.contri-btn {
				grid-area: contribtn;
				display: flex;
				justify-content: flex-end;
			}
		}
	}
}

@media only screen and (max-width: 600px) {
	.Contributor {
		.contributor-wrapper {
			.body {
				grid-template-areas: "imagen" "title" "leyenda" "contribtn";
				grid-template-columns: 1fr;
				.title {
					h4 {
						font-size: 1.5rem;
					}
					h3 {
						font-size: 2rem;
						margin-bottom: .5em;
					}
				}
				.contri-btn {
					margin-top: 1em;
				}
			}
		}
	}
}

.divisor {
	width: 100%;
	height: 40px;
	display: block;
	background-color: #333;
	background-image: url("./imgs/cross-stripes.png");
	background-repeat: repeat;
}