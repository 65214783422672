.footer {
	background-image: url("./imgs/cross-stripes.png");
	.footer-body {
		max-width: 960px;
		margin: 0 auto;
		padding: 0 1em;
		.datos {
			display: grid;
			grid-template-columns: repeat(3, 1fr);
			gap: 1em;
			justify-items: center;
			align-items: center;
			.logo {
				display: flex;
				svg {
					fill: #fff;
				}
				svg.escudovm {
					width: 60px;
				}
				svg.munvm {
					width: 160px;
				}
			}
			.social {
				background-color: transparent;
				display: flex;
				flex-direction: row;
				box-shadow: none;
				svg {
					fill: #fff;
				}
			}
		}
		p {
			font-size: 1rem;
			text-align: center;
		}
	}
}

@media (max-width: 600px) {
	.footer {
		.footer-body {
			.datos {
				display: grid;
				grid-template-columns: 1fr;
				gap: 1em;
				justify-items: center;
				.direccion {
					text-align: center;
				}
			}
		}
	}
}