$color-logo-municipalidad: #fff;

/* [Logo VM Somos] */
$txt-cm-color: #ee2737;
$txt-v-color: #ffb81c;
$txt-somos-color:  #cdc4bb; //#89827f;

/* [Logo Obras Sanitarias] */
$txt-os-color: #045db5;
$txt-rg-color: #5fc6e9;
$txt-m-color: #cdc4bb;

.Landing {
	background-color: #a2a2a2;
	position: relative;
	height: 100vh;
	width: 100%;
	//background: $degradado-oficial;
	//@extend .degradado-oficial;
	//@extend .degradado-oficial-mixed;
	padding-top: 8em;
	display: flex;
	flex-direction: column;
	//justify-content: center;
	align-items: center;
	.Landing-Municipalidad {
		display: flex;
		flex-direction: column;
		align-items: center;
		svg.logo-municipalidad {
			width: 218px;
			height: 56px;
		}
		h3 {
			font-size: 6em;
			color: #fff;
			margin-bottom: 3px;
			font-weight: 800;
			text-decoration: underline;
			text-shadow: 1px 1px 3px #000000;
		}
		p {
			color: #fff;
			font-size: 2.6em;
			text-shadow: 1px 1px 3px #000000;
		}
	}
	.Landing-Body {
		margin-top: 4em;
		width: 60%;
		display: flex;
		flex-wrap: wrap;
		justify-content: space-around;
		align-items: center;
		.Landing-logoMun, .Landing-logoOs {
			display: flex;
			flex-direction: column;
			span {
				margin-top: 1em;
				opacity: 0;
				text-align: center;
				text-shadow: 1px 1px 3px #000000;
				text-transform: uppercase;
				font-weight: 600;
				transition: opacity 2s ease-in;
			}
			&:hover {
				span {
					opacity: 1;
				}
			}
		}
	}
	.leyenda {
		position: absolute;
		left: 0;
		bottom: 0;
		width: 100%;
		background-color: rgba($color: #000000, $alpha: .5);
		text-align: center;
		padding: .4em 0;
		font-weight: 600;
	}
}

@media (max-width: 850px) {
	.Landing {
		padding-top: 4em;
		.Landing-Municipalidad {
			svg.logo-municipalidad {
				width: 218px;
				height: 56px;
			}
			h3 {
				font-size: 3.4em;
			}
			p {
				font-size: 1.5em;
			}
		}
		.Landing-Body {
			margin-top: 2em;
			flex-direction: column;
			svg.mun {
				width: 150px;
				filter: drop-shadow(0px 3px 3px rgba(0, 0, 0, 0.4));
				.txt-cm {
					fill: $txt-cm-color;
				}
				.txt-v {
					fill: $txt-v-color;
				}
				.txt-somos {
					fill: $txt-somos-color;
				}
			}
			svg.os {
				width: 150px;
				filter: drop-shadow(0px 3px 3px rgba(0, 0, 0, 0.4));
				.txt-os {
					fill: $txt-os-color;
				}
				.txt-rg {
					fill: $txt-rg-color;
				}
				.txt-m {
					fill: $txt-m-color;
				}
			}
		}
	}
}