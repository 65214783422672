.Wrapper {
	position: relative;
	padding-top: 100px;
	background-color: #333;
	min-height: 100vh;
	display: grid;
	grid-template-columns: 1fr;
	grid-template-rows: 1fr auto;
}

.HeaderBack {
	position: fixed;
	left: 0;
	top: 0;
	width: 100%;
	padding: 1em;
	background-color: rgba(255, 255, 255, .8);
	z-index: 10;
	box-shadow: 0 1px 2px 0 rgba(34,36,38,.15);
    border-bottom: 1px solid rgba(34,36,38,.15);
	.HeaderBack-body {
		display: flex;
		img {
			width: 100px;
			margin-left: 1em;
		}
	}
}