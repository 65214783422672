@import "scss/settings";
@import "scss/mixins";
@import "scss/preset";


@import "scss/landing";
@import "scss/logos";

@import "scss/wrapper";

@import "scss/nav";
@import "scss/contributor";
@import "scss/cards";
@import "scss/infos";
@import "scss/form";
@import "scss/noticias";
@import "scss/contact";
@import "scss/footer";

.ui .small input[type="file"] {
	padding: 7.5px;
}

#idreparticion {
	opacity: 1;
}

.customField {
	width: 100%;
	margin: 0 .5em;
	label {
		display: block;
		margin: 0 0 .28571429rem 0;
		color: rgba(0,0,0,.87);
		font-size: .92857143em;
		font-weight: 700;
		text-transform: none;
	}
	input, textarea {
		width: 100%;
	}
}