@mixin degradado {
	background: $primary-institucional-color;
	background: -moz-linear-gradient(-45deg,  $primary-institucional-color 0%, $secondary-institucional-color 100%);
	background: -webkit-linear-gradient(-45deg, $primary-institucional-color 0%,$secondary-institucional-color 100%);
	background: linear-gradient(135deg, $primary-institucional-color 0%,$secondary-institucional-color 100%);
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="#ee2737", endColorstr="#ffb81c",GradientType=1 );
}

@mixin degradado-oficial {
	background: $primary-institucional-color;
	background: -moz-linear-gradient(-45deg,  $primary-institucional-color 0%, $secondary-institucional-color 100%);
	background: -webkit-linear-gradient(-45deg, $primary-institucional-color 0%,$secondary-institucional-color 100%);
	background: linear-gradient(135deg, $primary-institucional-color 0%,$secondary-institucional-color 100%);
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="#ee2737", endColorstr="#ffb81c",GradientType=1 );
}

@mixin degradado-oficial-invertido {
	background: $primary-institucional-color;
	background: -moz-linear-gradient(45deg,  $primary-institucional-color 0%, $secondary-institucional-color 100%);
	background: -webkit-linear-gradient(45deg, $primary-institucional-color 0%,$secondary-institucional-color 100%);
	background: linear-gradient(45deg, $primary-institucional-color 0%,$secondary-institucional-color 100%);
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="#ee2737", endColorstr="#ffb81c",GradientType=1 );
}