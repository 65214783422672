/*
section#contacto {
	position: relative;
	min-height: 100vh;
	&::before {
		content: "";
		position: absolute;
		left: 0;
		top: 0;
		bottom: 0;
		right: 0;
		display: block;
		background-color: rgba(0, 0, 0, .7);
		z-index: 0;
	}
	.contacto-body {
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		z-index: 1;
		padding: 2em;
		border-radius: .5em;
		border: 1px solid #fff;
		background-color: #fff;
		h1 {
			position: relative;
			text-transform: uppercase;
			text-align: center;
			font-size: 3rem;
			//padding-bottom: 1em;
			color: $secondary-institucional-color;
			&::after {
				content: "";
				position: absolute;
				left: 50%;
				bottom: 0;
				height: 2px;
				width: 30%;
				display: block;
				background-color: #ccc;
				transform: translateX(-50%);
				border-radius: 1px;
			}
		}
		p {
			max-width: 750px;
			text-align: center;
			margin: 0 auto;
			font-size: .9rem;
			margin-top: 1em;
			font-style: italic;
			font-weight: 600;
		}
	}
}
*/

.social a {
	padding: 20px;
	color:#7b7c7c;
	font-size:1.1rem;
}

#contact {
	//position: absolute;
	//left: 50%;
	//top: 50%;
	//transform: translate(-50%,-50%);
	display: flex;
	justify-content: center;
	align-items: center;
	margin-bottom: 2em;
}

.social {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	background-color: #FFFFFF;
	box-shadow: 2px 2px 30px rgba(0,0,0,0.1);
	svg {
		width: 18px;
		fill: #000;
	}
}
.social a:hover{
	color:#000000;
	transition: all ease 0.3s;
}
.contact-box{
	max-width: 350px;
	background-color:#333;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 30px 30px;
	box-shadow: 2px 0px 30px rgba(0,0,0,0.1);
}
.c-heading h1{
	font-family: Roboto;
	color: #e7eef3;
	font-size: 2.5rem;
	font-weight: lighter;
	margin: 0px;
	padding: 0px;
}
.c-heading p{
	font-family: Roboto;
	color:#cecece;
	font-size: 0.8rem;
	font-weight: lighter;
	margin: 0px;
	padding: 0px;
	text-align: center;
}
.c-inputs{
	margin: 15px 0px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}
.c-inputs input{
	width:250px;
	height: 45px;
	background-color: transparent;
	border: none;
	border-bottom: 1px solid rgba(251,251,251,0.1);
	outline: none;
	margin: 10px 0px;
	padding: 10px;
	box-sizing: border-box;
	color: #f4f4f4;
	display: flex;
}
.c-inputs textarea{
	width:250px !important;
	height: 200px !important;
	outline: none;
	background-color: transparent;
	border:1px solid rgba(82,82,82,1);
	color: #f4f4f4;
	padding: 10px;
	font-size: 1.2rem;
	box-sizing: border-box;
}
.c-inputs button{
	width:200px;
	height: 40px;
	background-color: #FFFFFF;
	border: none;
	outline: none;
	margin-top: 20px;
	border-radius: 10px;
	box-shadow: 2px 2px 30px rgba(0,0,0,0.1);
	letter-spacing: 1px;
}
.c-inputs input:focus{
	border-bottom: 1px solid rgba(252,252,252,1.00);
	transition: all ease 0.5s;
}
.c-inputs textarea:focus{
	border: none;
	border-bottom: 1px solid rgba(255,255,255,1.00);
	transition: all ease 0.5s;
	background-color: rgba(0,0,0,0.1);
}
.c-inputs button:active{
	transform: scale(1.02);
}
.map{
	filter: grayscale(0.2);
	box-shadow: 2px 2px 30px rgba(0,0,0,0.2);
	border: 2px solid #FFFFFF;
}
@media(max-width:1100px){
	.map{
		display: none;
	}
}
@media(max-width:450px){
	.social{
		display: none;
	}
	.contact-box{
		width: 100%;
		height: 100vh;
		box-sizing: border-box;
	}
	#contact{
		position: static;
		transform: translate(0,0);
		width:100%;
		height: 100vh;
		box-sizing: border-box;
	}
	.c-heading h1{
		font-size: 2.2rem;
		text-align: center;
	}
	
}