section#noticias {
	.img-link {
		grid-area: imglink;
		a {
			img {
				width: 100%;
				height: 386px;
				object-fit: cover;
			}
		}
	}
	.video {
		grid-area: video;
		video {
			height: 386px;
		}
	}
	.noticias-carousel {
		grid-area: carousel;
		img {
			max-height: 386px;
			object-fit: cover;
		}
	}
	max-width: 1200px;
	margin: 0 auto;
	display: grid;
	grid-template-columns: 2fr 1fr;
	//grid-template-areas: "video carousel";
	grid-template-areas: "imglink carousel";
	gap: 1em;
}

@media (max-width: 800px) {
	section#noticias {
		.img-link {
			a {
				img {
					width: 100%;
					height: auto;
				}
			}
		}
		.video {
			video {
				width: 100%;
				height: auto;
			}
		}
		max-width: 1200px;
		margin: 0 auto;
		display: grid;
		grid-template-columns: 1fr;
		//grid-template-areas: "video" "carousel";
		grid-template-areas: "imglink" "carousel";
		gap: 1em;
	}
}
