@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,300;1,400;1,600;1,700;1,800&display=swap");

//General

$default-text-color: #333;
$primary-font: "Open Sans", sans-serif;

// Cards

$primary-institucional-color: var(--institucional1);
$secondary-institucional-color: var(--institucional2);