form {
	padding: 1em;
	.form-field {
		width: 100%;
		display: flex;
		flex-direction: column;
		label {
			margin-bottom: .5em;
			color: #000;
			font-weight: 600;
			font-size: .7rem;
		}
		input {
			width: 100%;
			padding: .5em 1em;
			appearance: none;
			border: 1px solid #ccc;
			border-radius: .5em;
			outline: none;
		}
	}
}